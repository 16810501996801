import React from 'react';
import ChatWindow from './components/ChatWindow';

function App() {
  const [threat, setThreat] = React.useState(true);
  const [score, setScore] = React.useState(0);
  const [responseLLM, setResponseLLM] = React.useState(1); // 1 = GPT-4 Turbo, 2 = GPT-3.5 Turbo, 3 = GPT-4 
  const [threatLLM, setThreatLLM] = React.useState(2); // 1 = GPT-4 Turbo, 2 = GPT-3.5 Turbo, 3 = GPT-4
  const [token, setToken] = React.useState(0);

  const [passcode, setPasscode] = React.useState("");

  const passcodeHandler = (event) => {
    setPasscode(event.target.value);
  }

  const handlePasscodeSubmit = (event) => {
    event.preventDefault();

    // query api to get token
    fetch('https://gen-ai-predator-detection-dev.catliadotnet.workers.dev/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        //"Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({"passcode": passcode}),
    }).then(response => {
      return response.json();
    }).then(text => {
      console.log("Token: "+text.token)
      setToken(text.token);
    }).catch(error => {
      console.log(error);
    });

    setPasscode('');
  };

  const responseLLMHandler = (event) => {
    setResponseLLM(event.target.value);
  }

  const threatLLMHandler = (event) => {
    setThreatLLM(event.target.value);
  }

  const checkHandler = () => {
    setThreat(!threat)
  }

  const [messages, setMessages] = React.useState([
    // { text: 'Hello!', from: 'user', threat: 0 },
    // { text: 'Hi there!', from: 'Zoe' },
  ]);

  const handleSubmit = (messageText) => {
    const newMessage = { text: messageText, from: 'user' };

    var aiMessageText = "";
    var aiMessage = {text: aiMessageText, from: 'Zoe'};
    setMessages([...messages, newMessage]);

    // send the most recent 5 messages to the AI
    var msgText = [];
    var i = 0;
    var msgCount = Math.min(5, messages.length);
    while (i < msgCount) {
      const msg = messages[messages.length - (msgCount - i)].text;
      const usr = messages[messages.length - (msgCount - i)].from;
      msgText.push({role: usr, content: msg});
      i++;
    }

    msgText.push({role: "user", content: messageText});

    // query AI api and print response
    fetch('https://gen-ai-predator-detection-dev.catliadotnet.workers.dev/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        //"Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({"message": msgText, "key": token, "responseLLM": responseLLM, "threatLLM": threatLLM}),
    })
    .then(response => {
      return response.json();
    })
    .then(text => {
      console.log("Response: "+text.message)
      setMessages([...messages, newMessage, aiMessage]);

      var aiMessageTxt = text.message;

      // calculate how long it would take to type the message based on the length of the message
      var typingRate = 100; // 100ms per character
      var typingTime = aiMessageTxt.length * typingRate;

      // overide typing time for now - TODO: make it a toggle
      typingTime = 200;

      // set a timeout to send the message after the typing time
      setTimeout(function() {
        aiMessage.text = aiMessageTxt;
        var msgThreat = text.threat;

        if (msgThreat === "Low") {
          newMessage.threat = 0;
          setScore(score *0.95);
        } else if (msgThreat === "Medium") {
          newMessage.threat = 1;
          setScore(score + 25);
        }
        else if (msgThreat === "High") {
          newMessage.threat = 2;
          setScore(score + 50);
        } else {
          newMessage.threat = 0;
        }

        setMessages([...messages, newMessage, aiMessage]);
      }, typingTime);
    });
  
  };

  return (
    <div className="container mx-auto h-screen">
      <div className={`${token == 0 ? "visible h-screen" : "hidden" }`} >
        <form onSubmit={handlePasscodeSubmit} className="flex flex-col justify-center items-center h-full w-full">
          <h3 className="my-3" >Enter Passcode</h3>
          <input type="password" onChange={passcodeHandler} className="w-1/3 mr-2 px-2 py-1 h-10 rounded-md text-gray-200 placeholder-slate-500 border-slate-600 border focus:outline-none focus:ring-2 focus:ring-slate-600 bg-gradient-to-tr from-slate-800 to-slate-700" />
        </form>
      </div>
      <div className={`${token == 0 ? "hidden" : "visible flex flex-row flex-wrap h-full"}`}>
        {/* <aside className="w-full sm:w-1/3 md:w-1/4 px-2 mb-auto shadow-lg rounded-md ">
          <div className="sticky top-0 p-4 w-full">
            <h1 className="font-bold mb-2 text-base xl:text-2xl">Anti-Human Trafficking Demo</h1>
            <p className="hidden md:block text-gray-400 text-sm">
              This is a demo of a chat app that uses multiple LLM to generate responses that mimic a young child called Zoe.
            </p>
            
            <div className="relative xl:mt-10">
              <div>
                <label htmlFor="threat" className="inline-block my-2 mr-2 text-sm xl:text-base">Show Message Threat</label>
              </div>
              <div>
                <input type="checkbox" checked={threat} onChange={checkHandler} className="focus:outline-none relative appearance-none inline-block h-[30px] w-[54px] cursor-pointer rounded-full bg-slate-400 shadow-md transition-all after:content-[''] after:absolute after:top-[3px] after:left-[3px] after:h-6 after:w-6 after:rounded-full after:bg-white after:shadow-md after:transition-all checked:bg-slate-700 checked:after:translate-x-6" id="threat" />
              </div>
            </div>

            <div className="relative xl:mt-2">
              <div>
                <label htmlFor="response-llm" className="inline-block my-2 mr-2 text-sm xl:text-base">Response LLM</label>
              </div>
              <div>
                <select value={responseLLM} onChange={responseLLMHandler} className="focus:outline-none bg-slate-700 border border-slate-600 text-slate-200 text-xs xl:text-sm rounded-lg focus:ring-slate-500 focus:border-slate-500 block w-full p-2.5 ">
                  <option value="1">GPT-4 Turbo</option>
                  <option value="2">GPT-3.5 Turbo</option>
                  <option value="3">GPT-4</option>
                </select>
              </div>
            </div>

            <div className="relative xl:mt-2">
              <div>
                <label htmlFor="threat-llm" className="inline-block my-2 mr-2 text-sm xl:text-base">Threat Detection LLM</label>
              </div>
              <div>
                <select value={threatLLM} onChange={threatLLMHandler} className="focus:outline-none bg-slate-700 border border-slate-600 text-slate-200 text-xs xl:text-sm rounded-lg focus:ring-slate-500 focus:border-slate-500 block w-full p-2.5 ">
                  <option value="1">GPT-4 Turbo</option>
                  <option value="2">GPT-3.5 Turbo</option>
                  <option value="3">GPT-4</option>
                </select>
              </div>
            </div>

            <div className="relative xl:mt-2 mb-5 xl:mb-6">
              <div>
                <label htmlFor="threat-score" className="inline-block my-2 mr-2 text-sm xl:text-base">Threat Score</label>
              </div>
              <div>
                <input type="range" value={score} className="transition-all duration-500 w-full h-2 cursor-default bg-gradient-to-r from-green-700 to-red-700 rounded-lg appearance-none" id="score" disabled />
              </div>
              <span className="text-xs xl:text-sm text-slate-500 absolute start-0 -bottom-6">Low (0)</span>
              <span className="text-xs xl:text-sm text-slate-500 absolute start-1/2 -translate-x-1/2 rtl:translate-x-1/2 -bottom-6">Medium (50)</span>
              <span className="text-xs xl:text-sm text-slate-500 absolute end-0 -bottom-6">High (100)</span>
            </div>

          </div>
        </aside> */}
        <main className="w-full sm:w-2/3 md:w-3/4 px-2">
          <ChatWindow messages={messages} threat={threat} onSubmit={handleSubmit} />
        </main>
        
      </div>
    </div>
  );
}

export default App;
