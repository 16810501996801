import React from 'react';

function ChatMessages(props) {
  const { messages, showThreat } = props;

  const messageStyle = (message) => {
    if (message.from === 'user') {
      if(!showThreat){
        return 'bg-gradient-to-tr from-blue-800 to-blue-600 text-gray-200 self-end';
      } else {
        if(message.threat === 0){
          return 'bg-gradient-to-tr from-green-800 to-green-600 text-green-200 self-end';
        } else if(message.threat === 1){
          return 'bg-gradient-to-tr from-yellow-800 to-yellow-600 text-yellow-200 self-end';
        } else if(message.threat === 2){
          return 'bg-gradient-to-tr from-red-800 to-red-600 text-red-100 self-end';
        } else {
          return 'bg-gradient-to-tr from-blue-800 to-blue-600 text-gray-200 self-end';
        }
      }
    }else{
        return 'bg-gradient-to-tr from-slate-800 to-slate-600 text-gray-200 self-start';
    }
  };

  const fromStyle = (message) => {
    if (message.from === 'user') {
      if (showThreat && message.threat === 0) {
        return 'text-green-300';
      }else if (showThreat && message.threat === 1) {
        return 'text-yellow-300';
      }else if (showThreat && message.threat === 2) {
        return 'text-red-300';
      }else {
        return 'text-blue-300';
      }
    } else {
      return 'text-slate-300';
    }
  };

  return (
    <div className="overflow-auto scroll-smooth h-[calc(100vh-80px)] scrollbar scrollbar-thumb-slate-700 px-2">
      <div className="flex flex-col space-y-2 mb-auto">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`max-w-md px-5 py-4 rounded-md shadow-lg transition-all ${messageStyle(message)}`}
          >
            <div className={`text-xs ${fromStyle}`}>{message.from}</div>
            <div className={`${ message.text == "" ? "block" : "hidden"} flex space-x-1 p-2 rounded-full justify-center items-center`}>
              <div className="blue-circle bg-slate-800 animate-bounce delay-1000 p-0.5 w-1 h-1 rounded-full"></div>
              <div className="green-cirlce bg-slate-800 animate-bounce delay-500 p-0.5 w-1 h-1 rounded-full"></div>
              <div className="red-circle bg-slate-800 animate-bounce p-0.5 w-1 h-1 rounded-full"></div>
            </div>
            {message.text}
          </div>
        ))}
      </div>
    </div>
  );
}

export default ChatMessages;
