import React, { useState } from 'react';

function MessageInputForm(props) {
  const [messageText, setMessageText] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    props.onSubmit(messageText);
    setMessageText('');
  };

  const handleInputChange = (event) => {
    setMessageText(event.target.value);
  };

  return (
    <form onSubmit={handleSubmit} className="flex justify-between items-center">
      <input
        type="text"
        placeholder="Type a message..."
        value={messageText}
        onChange={handleInputChange}
        className="flex-grow mr-2 px-2 py-1 h-10 rounded-md text-gray-200 placeholder-slate-500 border-slate-600 border focus:outline-none focus:ring-2 focus:ring-slate-600 bg-gradient-to-tr from-slate-800 to-slate-700"
      />
      <button
        type="submit"
        className="px-4 py-2 rounded-md bg-blue-700 text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-600"
      >
        Send
      </button>
    </form>
  );
}

export default MessageInputForm;
