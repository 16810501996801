import React from 'react';
import ChatMessages from './ChatMessages';
import MessageInputForm from './MessageInputForm';

function ChatWindow(props) {
  const { messages, threat, onSubmit } = props;

  return (
    <div className="flex flex-col justify-between py-4 px-3 h-full">
      <ChatMessages messages={messages} showThreat={threat} />
      <MessageInputForm onSubmit={onSubmit} />
    </div>
  );
}

export default ChatWindow;
